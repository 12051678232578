import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { BaseFilter, Pagination } from '@shared/models/filter.model';
import { Constants, ORDER_BY_DIRECTION } from '@shared/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  constructor(public router: Router) {}

  public static getParamByName(name: string, route: ActivatedRouteSnapshot): unknown {
    return route.queryParams[name] ?? '';
  }

  public static convertToSingleValueArray = (value: any): any[] | undefined => {
    if (!value) {
      return undefined;
    } else if (Array.isArray(value)) {
      return value;
    } else {
      return [value];
    }
  };

  private static getOrderByParam(route: ActivatedRouteSnapshot): {
    orderByField: string;
    orderByDirection: ORDER_BY_DIRECTION;
  } {
    return {
      orderByField: route.queryParams['orderByField'] || undefined,
      orderByDirection: route.queryParams['orderByDirection'] || undefined,
    };
  }

  public getBaseQueryParams<T>(route: ActivatedRouteSnapshot): BaseFilter<T> {
    return {
      search: (QueryParamsService.getParamByName('search', route) as string) || undefined,
      ...QueryParamsService.getOrderByParam(route),
      ...this.getPaginationQueryParams(route),
    };
  }

  public getPaginationQueryParams(route: ActivatedRouteSnapshot): Pagination {
    return {
      page: route.queryParams['page'] ?? Constants.DEFAULT_FIRST_PAGE_NUMBER,
      size: route.queryParams['size'] ?? Constants.DEFAULT_PAGE_SIZE,
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  public setQueryParams(queryParams: any): void {
    Object.keys(queryParams).forEach((key) => {
      if (queryParams[key] === '') {
        queryParams[key] = undefined;
      }
    });
    this.router.navigate([], {
      queryParams: queryParams ? { ...queryParams } : {},
      queryParamsHandling: 'merge',
    });
  }

  public clearQueryParams(route: ActivatedRouteSnapshot): void {
    this.router.navigate([], {
      queryParams: {},
    });
  }
}
