import { Component, OnDestroy, signal, ViewChild } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProcessingBatch } from '@shared/models/parking-ticket/processing-batch.model';
import { CdkStepper } from '@angular/cdk/stepper';
import { InvisibleStepperComponent } from '@shared/components/invisible-stepper/invisible-stepper.component';
import {
  ProcessingBatchExceptionCode,
  ProcessingBatchHttpService,
} from '@shared/services/processing-batch-http.service';
import { ToastService } from '@shared/services/toast.service';
import { TranslocoService } from '@jsverse/transloco';
import { StatusEventsService } from '@shared/services/status-events.service';
import { ProcessingBatchPaymentInformationSSEMessage, SseMessage } from '@shared/models/sse-message.model';
import { ProcessingBatchPaymentStatus } from '@shared/types/payment.status.enum';
import { Constants } from '@shared/constants/constants';
import { ProcessingBatchPaymentTypeEnum } from '@shared/types/processing-batch-payment-type.enum';
import { ProcessingBatchPayment } from '@shared/models/parking-ticket/processing-batch-payment';
import { BatchStates } from '@shared/components/batch-list/batch-detail/batch-detail.component';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import * as Sentry from '@sentry/angular';
import firebase from 'firebase/compat';

@Component({
  selector: 'stiilt-pay-later-modal',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './pay-later-modal.component.html',
  styles: ``,
})
export class PayLaterModalComponent implements OnDestroy {
  @ViewChild(CdkStepper) public stepper!: InvisibleStepperComponent;

  public processingBatch!: ProcessingBatch;
  public processingBatchState!: BatchStates;
  public clientSecret!: string;
  public paymentDetailsSignal = signal<ProcessingBatchPaymentInformationSSEMessage | null>(null);
  private batchPayment?: ProcessingBatchPayment;

  protected readonly PayLaterSteps = PayLaterSteps;
  protected readonly Math = Math;
  private activeRef: firebase.database.Query | undefined;

  constructor(
    private readonly processingBatchHttpService: ProcessingBatchHttpService,
    private readonly dynamicDialogConfig: DynamicDialogConfig<{
      batch: ProcessingBatch;
      batchState: BatchStates;
    }>,
    private readonly dynamicDialogRef: DynamicDialogRef,
    private readonly toastService: ToastService,
    private readonly translateService: TranslocoService,
    public readonly statusEventService: StatusEventsService,
    public readonly db: AngularFireDatabase,
  ) {
    this.processingBatch = this.dynamicDialogConfig.data?.batch!;
    this.processingBatchState = this.dynamicDialogConfig.data?.batchState!;
    this.processingBatchHttpService.aojojo(this.processingBatch?.id!).subscribe({
      next: (result) => {
        this.stepper.selectedIndex = PayLaterSteps.RECAP;
      },
      error: (err) => {
        if (
          err.status === 499 &&
          (err.error.errorCode === ProcessingBatchExceptionCode.CHECKOUT_SESSION_ALREADY_ACTIVE ||
            err.error.errorCode === ProcessingBatchExceptionCode.PAYMENT_PROCESS_ALREADY_ACTIVE)
        ) {
          this.subscribeToRTDB();
          this.stepper.selectedIndex = PayLaterSteps.PT_CHECK;
        } else {
          Sentry.captureException(err);
          this.toastService.error(this.translateService.translate('app.error_occurred'));
        }
      },
    });
  }
  ngOnDestroy(): void {
    this.unsubscribeFromRTDB();
  }

  public getCurrentStepIdentifier(): string | number {
    const currentStepIndex = this.stepper?.selectedIndex;
    return this.stepper?.steps.get(currentStepIndex)!.identifier!;
  }

  private nextStep(): void {
    this.stepper.next();
    if (this.getCurrentStepIdentifier() === PayLaterSteps.PT_CHECK) {
      this.processingBatchHttpService.generatePayment(this.processingBatch.id).subscribe(() => {
        this.subscribeToRTDB();
      });
    }
  }

  public handleActionButton(): void {
    this.nextStep();
  }

  private subscribeToRTDB(): void {
    this.activeRef = this.db.database
      .ref(`${this.processingBatch.organisationId}/${this.processingBatch?.uuid}`);

    this.activeRef.on('value', (snapshot: firebase.database.DataSnapshot) => {
      this.paymentDetailsSignal.set(snapshot.val());
      const batch: ProcessingBatchPaymentInformationSSEMessage = snapshot.val();
      if (batch?.invoiceUrl) {
        this.dynamicDialogRef.close(true);
        window.open(batch.invoiceUrl, '_self');
        this.unsubscribeFromRTDB();
      }
      if (batch?.clientSecret) {
        this.clientSecret = batch.clientSecret!;
        this.stepper.selectedIndex = PayLaterSteps.PAYMENT;
        this.unsubscribeFromRTDB();
      }
    });
  }

  private unsubscribeFromRTDB() {
    this.activeRef?.off();
    this.activeRef = undefined;
  }

  public handlePaymentSuccess(): void {
    this.stepper.selectedIndex = PayLaterSteps.WAITING_FOR_PAYMENT;
    this.processingBatchHttpService.selectedProcessingBatch$.subscribe((batch) => {
      this.batchPayment = batch.processingBatchPayments.find(
        (payment) => payment.type === ProcessingBatchPaymentTypeEnum.STANDARD,
      );
    });
    this.processingBatchHttpService.getProcessingBatchById(this.processingBatch.id);

    const ref = setInterval(() => {
      if (
        [ProcessingBatchPaymentStatus.REQUIRES_CAPTURE, ProcessingBatchPaymentStatus.SUCCEEDED].includes(
          this.batchPayment!.paymentStatus!,
        )
      ) {
        clearInterval(ref);
        this.dynamicDialogRef.close(true);
      } else {
        this.processingBatchHttpService.getProcessingBatchById(this.processingBatch.id);
        this.processingBatchHttpService.getAllPaginatedProcessingBatches(
          Constants.DEFAULT_FIRST_PAGE_NUMBER,
          Constants.DEFAULT_PAGE_SIZE,
        );
      }
    }, 3000);
  }
}

export enum PayLaterSteps {
  RECAP = 0,
  PT_CHECK = 1,
  PAYMENT = 2,
  WAITING_FOR_PAYMENT = 3,
}
