<stiilt-dialog [title]="'current_batch.pay_later_modal.title' | transloco: { data: processingBatch.uuid }"
               maxHeightClassName="max-h-[90vh]" [withFooter]="getCurrentStepIdentifier() === PayLaterSteps.RECAP">
  <stiilt-invisible-stepper #stepper>
    <stiilt-step [identifier]="PayLaterSteps.RECAP">
      <div class="flex-c gap-5">
        <p>{{ 'current_batch.pay_later_modal.explanation.line1' | transloco }}</p>
        <p>{{ 'current_batch.pay_later_modal.explanation.line2' | transloco }}</p>
        <p>{{ 'current_batch.pay_later_modal.explanation.line3' | transloco }}</p>
      </div>
    </stiilt-step>
    <stiilt-step [identifier]="PayLaterSteps.PT_CHECK">
      <ng-container *ngIf="paymentDetailsSignal()">
        @let data = paymentDetailsSignal();
        @let total = data?.numberOfParkingTickets!;
        @let parkingTicketsProcessed = total - data?.numberOfParkingTicketsLeftToProcess!;
        @let percentage = Math.floor((parkingTicketsProcessed / total) * 100);

        <div class="flex-c gap-5">
          <div class="flex-c gap-2">
            <p><b>{{ 'current_batch.pay_later_modal.details.total' | transloco }}</b> {{ total }}</p>
            <p>
              <b>{{ 'current_batch.pay_later_modal.details.pts_left_to_check' | transloco }}</b> {{ data?.numberOfParkingTicketsLeftToProcess }}
            </p>
            <p>
              <b>{{ 'current_batch.pay_later_modal.details.total_paid_by_users' | transloco }}</b> {{ data?.amountPaidByUsers }}
              €</p>
            <p>
              <b>{{ 'current_batch.pay_later_modal.details.amount_left_to_pay' | transloco }}</b> {{ data?.amountLeftToPay }}
              €</p>
          </div>
          <p-progressBar [value]="percentage">
            <ng-template pTemplate="content" let-value>
              <span>{{ value }}%</span>
            </ng-template>
          </p-progressBar>
        </div>
      </ng-container>
    </stiilt-step>
    <stiilt-step [identifier]="PayLaterSteps.PAYMENT">
      <stiilt-stripe-payment [clientSecret]="clientSecret"
                             (paymentSuccess)="handlePaymentSuccess()"></stiilt-stripe-payment>
    </stiilt-step>
    <stiilt-step [identifier]="PayLaterSteps.WAITING_FOR_PAYMENT">
      <div
        class="flex-col-center h-full">
        <stiilt-spinner [indeterminate]="true" />
      </div>
    </stiilt-step>
  </stiilt-invisible-stepper>
  <ng-template stiiltTemplate="footer">
    <div class="flex flex-row w-full justify-between">
      <div></div>

      <p-button *ngIf="getCurrentStepIdentifier() === PayLaterSteps.RECAP"
                [label]="'current_batch.pay_later_modal.next' | transloco" (click)="handleActionButton()"
                [loading]="(statusEventService.getHttpStatus() | async)!" size="small"></p-button>
    </div>
  </ng-template>
</stiilt-dialog>
